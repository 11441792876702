<template>
    <div>
        <h2 class="text-center mt-5" style="color: rgba(99, 104, 236, 1)">รายงานภาพรวมของจำนวนการกรอกข้อมูล</h2>
        <div class="container mb-5 mt-3 main-form-oscc px-10 py-5">
            <b-row>
                <b-col md="4">
                    <span>เขตสุขภาพ</span>
                    <div>
                        <b-form-select style="border-radius: 20px" v-model="selected_area" :options="optionsarea  || []"
                                       size="sm" class="mt-3" v-on:change="changeArea">

                        </b-form-select>
                    </div>
                </b-col>
                <b-col md="4">
                    <span>จังหวัด</span>
                    <div>
                        <b-form-select text-field="name" value-field="code" style="border-radius: 20px"
                                       v-model="selected_province" :options="optionsprovince  || []" size="sm"
                                       class="mt-3" v-on:change="changeProvince">

                        </b-form-select>
                    </div>
                </b-col>
                <b-col md="4">
                    <span>อำเภอ</span>
                    <div>
                        <b-form-select text-field="name" value-field="district_id" v-model="selected_district"
                                       style="border-radius: 20px" :options="optionsdistrict || []" size="sm"
                                       class="mt-3" v-on:change="changeDistrict">
                        </b-form-select>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-5">
                <b-col md="5">
                    <label>ประเภทของโรงพยาบาล</label>
                    <b-form-select
                            v-if="selected_area != '13'"
                            v-model="hospital_type"
                            class="form-control"
                            @change="setHospital"
                    >
                        <b-form-select-option value=""
                        >เลือกประเภททั้งหมดของโรงพยาบาล
                        </b-form-select-option
                        >

                        <b-form-select-option
                                v-for="(item, i) in hospitalType"
                                :key="i"
                                :value="item"
                        >{{ item.key }}
                        </b-form-select-option
                        >
                    </b-form-select>

                    <b-form-select
                            v-else
                            v-model="hospital_type"
                            class="form-control"
                            @change="setHospital"
                    >
                        <b-form-select-option value=""
                        >เลือกประเภททั้งหมดของโรงพยาบาล
                        </b-form-select-option
                        >

                        <b-form-select-option
                                v-for="(item, i) in thirteenType"
                                :key="i"
                                :value="item"
                        >{{ item.key }}
                        </b-form-select-option
                        >
                    </b-form-select>
                </b-col>
                <b-col md="5">
                    <label>โรงพยาบาล</label>
                    <b-form-select
                            :disabled="
                      !selected_area &&
                      !selected_province &&
                      !hospital_type
                    "
                            v-model="selected_hospital"
                            class="form-control"
                    >
                        <b-form-select-option value=""
                        >เลือกโรงพยาบาลทั้งหมด
                        </b-form-select-option
                        >

                        <b-form-select-option
                                v-for="(item, i) in filterhospital"
                                :key="i"
                                :value="item.hospcode"
                        >{{ item.name }}
                        </b-form-select-option
                        >
                    </b-form-select>
<!--                                        <b-form-select text-field="full_name" value-field="hospcode" style="border-radius: 20px" v-model="selected_hospital" onchange='this.size=4; this.blur();' :options="optionshospital  || []" size="sm" class="mt-3">-->
<!--                                        </b-form-select>-->
                </b-col>
                <b-col md="2" class="mt-8">
                    <div>
                        <b-button class="w-100" style="border-radius: 20px" variant="primary" @click="showData">แสดง
                        </b-button>
                    </div>
                </b-col>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    v-model="dateSelected"
                    :aria-describedby="ariaDescribedby"
                >
                  <div class="row violence-radio px-3">
                    <div class="col-6 py-4 bg-grey-2-theme">
                      <label>วันที่เกิดเหตุ</label>
                      <div class="row align-items-center">
                        <div class="col-1">
                          <b-form-radio value="event"></b-form-radio>
                        </div>
                        <div class="col-5">
                          <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  outlined
                                  rounded
                                  class="mt-2"
                                  dense
                                  v-model="computedStartEventDateFormatted"
                                  append-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date.startEventDate"
                                @input="menu = false"
                                locale="th-th"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                        <div class="col-1">-</div>
                        <div class="col-5">
                          <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  outlined
                                  rounded
                                  class="mt-2"
                                  dense
                                  v-model="computedEndEventDateFormatted"
                                  append-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date.endEventDate"
                                @input="menu2 = false"
                                locale="th-th"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 py-4 bg-grey-2-theme">
                      <label>วันที่มาโรงพยาบาล</label>
                      <div class="row align-items-center">
                        <div class="col-1">
                          <b-form-radio value="hospital"></b-form-radio>
                        </div>
                        <div class="col-5">
                          <v-menu
                              v-model="menu3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  outlined
                                  rounded
                                  class="mt-2"
                                  dense
                                  v-model="computedStartHospitalDateFormatted"
                                  append-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date.startHospitalDate"
                                @input="menu3 = false"
                                locale="th-th"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                        <div class="col-1">-</div>
                        <div class="col-5">
                          <v-menu
                              v-model="menu4"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  outlined
                                  rounded
                                  class="mt-2"
                                  dense
                                  v-model="computedEndHospitalDateFormatted"
                                  append-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date.endHospitalDate"
                                @input="menu4 = false"
                                locale="th-th"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form-radio-group>
              </b-form-group>
            </b-row>
        </div>
        <div class="container mb-5 mt-3 px-10 py-5">
            <b-row class="mt-10">
                <b-col cols="10">
                </b-col>
                <b-col cols="2">
                    <b-button @click="excel" class="w-100" variant="primary" style="border-radius: 20px;">ส่งออก Excel
                    </b-button>
                </b-col>
            </b-row>
            <table class="table table-striped w-100 mt-5 text-center">
                <thead class="header-table-oscc">
                <tr>
                    <th>เขตสุขภาพ</th>
                    <th>จังหวัด</th>
                    <th>อำเภอ</th>
                    <th>โรงพยาบาล</th>
                    <th>การกระทำ<br/>
                        ความรุนแรง
                    </th>
                    <th>การตั้งครรภ์<br/>
                        ไม่พึงประสงค์
                    </th>
                    <th>การกระทำความรุนแรง<br/>
                        และการตั้งครรภ์ไม่พึงประสงค์
                    </th>
                </tr>
                </thead>
                <tbody v-for="(item,i) in list" v-bind:key="i" style="border-style: none">
                <tr>
                    <td class="body-table-oscc">
                        <template v-if="i==0 || item[1] != list[i-1][1]">
                            เขตที่ {{ item[1] }}
                        </template>
                    </td>
                    <td class="body-table-oscc">
                        <template v-if="i==0 || item[2] != list[i-1][2]">
                            {{ item[2] }}
                        </template>
                    </td>
                    <td class="body-table-oscc">
                        <template v-if="i==0 || item[3] != list[i-1][3]">
                            {{ item[3] }}
                        </template>
                    </td>
                    <td class="body-table-oscc">{{ item[4] }}</td>
                    <td>{{ item[5] }}</td>
                    <td>{{ item[6] }}</td>
                    <td>{{ item[7] }}</td>
                </tr>
                <tr v-if="!list[i+1] || item[3] != list[i+1][3]">
                    <td class="body-table-oscc"></td>
                    <td class="body-table-oscc"></td>
                    <td class="body-table-oscc"></td>
                    <td class="body-table-oscc">รวม</td>
                    <td>{{ sum[item[3]][0] }}</td>
                    <td>{{ sum[item[3]][1] }}</td>
                    <td>{{ sum[item[3]][2] }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment/moment";

export default {
    name: "ReportSummaryRecorder",
    data() {
        return {
            dateSelected: 'event',
            selected_district: '',
            selected_province: '',
            selected_area: '',
            selected_hospital: '',
            hospital_type: '',
            optionsdistrict: [],
            optionsprovince: [],
            optionsarea: [
                {'text': 'ทุกเขตสุขภาพ', 'value': ''},
                {'text': 'เขตสุขภาพที่ 1', 'value': '1'},
                {'text': 'เขตสุขภาพที่ 2', 'value': '2'},
                {'text': 'เขตสุขภาพที่ 3', 'value': '3'},
                {'text': 'เขตสุขภาพที่ 4', 'value': '4'},
                {'text': 'เขตสุขภาพที่ 5', 'value': '5'},
                {'text': 'เขตสุขภาพที่ 6', 'value': '6'},
                {'text': 'เขตสุขภาพที่ 7', 'value': '7'},
                {'text': 'เขตสุขภาพที่ 8', 'value': '8'},
                {'text': 'เขตสุขภาพที่ 9', 'value': '9'},
                {'text': 'เขตสุขภาพที่ 10', 'value': '10'},
                {'text': 'เขตสุขภาพที่ 11', 'value': '11'},
                {'text': 'เขตสุขภาพที่ 12', 'value': '12'},
                {'text': 'เขตสุขภาพที่ 13', 'value': '13'},
            ],
            hospitalType: [
                {key: "โรงพยาบาลศูนย์_A", value: "A"},
                {key: "โรงพยาบาลทั่วไป_S", value: "S"},
                {key: "โรงพยาบาลทั่วไปขนาดเล็ก_M1", value: "M1"},
                {key: "โรงพยาบาลชุมชนแม่ข่าย_M2", value: "M2"},
                {key: "โรงพยาบาลชุมชนขนาดใหญ่_F1", value: "F1"},
                {key: "โรงพยาบาลชุมชนขนาดกลาง_F2", value: "F2"},
                {key: "โรงพยาบาลชุมชนขนาดเล็ก_F3", value: "F3"},
            ],
            thirteenType: [
                {key: "สำนักงานสาธารณสุขจังหวัด", value: "สำนักงานสาธารณสุขจังหวัด"},
                {key: "สำนักงานสาธารณสุขอำเภอ", value: "สำนักงานสาธารณสุขอำเภอ"},
                {key: "สถานีอนามัย", value: "สถานีอนามัย"},
                {key: "สถานบริการสาธารณสุขชุมชน", value: "สถานบริการสาธารณสุขชุมชน"},
                {key: "โรงพยาบาลศูนย์", value: "โรงพยาบาลศูนย์"},
                {key: "โรงพยาบาลทั่วไป", value: "โรงพยาบาลทั่วไป"},
                {key: "โรงพยาบาลชุมชน", value: "โรงพยาบาลชุมชน"},
                {key: "โรงพยาบาลนอก สป.สธ.", value: "โรงพยาบาลนอก สป.สธ."},
            ],
            optionshospital: [],
            filterhospital: [],
            one_time: false,
            list: [],
            sum: [],
            date: {
              startEventDate: "",
              endEventDate: "",
              startHospitalDate: "",
              endHospitalDate: "",
            },
        }
    },
    computed: {
      computedStartEventDateFormatted() {
        if (!this.date.startEventDate) return null;
        const [year, month, day] = this.date.startEventDate.split("-");
        return `${day}/${month}/${parseInt(year) + 543}`;
      },
      computedEndEventDateFormatted() {
        if (!this.date.endEventDate) return null;
        const [year, month, day] = this.date.endEventDate.split("-");
        return `${day}/${month}/${parseInt(year) + 543}`;
      },
      computedStartHospitalDateFormatted() {
        if (!this.date.startHospitalDate) return null;
        const [year, month, day] = this.date.startHospitalDate.split("-");
        return `${day}/${month}/${parseInt(year) + 543}`;
      },
      computedEndHospitalDateFormatted() {
        if (!this.date.endHospitalDate) return null;
        const [year, month, day] = this.date.endHospitalDate.split("-");
        return `${day}/${month}/${parseInt(year) + 543}`;
      },
    },
    created() {
        this.showLoading()
        this.setData()
    },
    methods: {
        setHospital() {
            if (this.hospital_type && !this.selected_province)
                this.filterhospital = this.optionshospital.filter(x => (x.type_code == this.hospital_type.value))
            else if (this.hospital_type && this.selected_province)
                this.filterhospital = this.optionshospital.filter(x => (x.type_code == this.hospital_type.value && x.province_code == this.selected_province))
            else if (!this.hospital_type && this.selected_province)
                this.filterhospital = this.optionshospital.filter(x => (x.province_code == this.selected_province))
        },
        formatDate(date) {
          return date
              ? moment(date, "YYYY-MM-DD").add(543, "years").format("YYYY-MM-DD")
              : "";
        },
        showData() {
            let data = {
                area: this.selected_area,
                province: this.selected_province,
                district: this.selected_district,
                hospital: this.selected_hospital,
                start_event_date:
                    this.dateSelected === "event"
                        ? this.formatDate(this.date.startEventDate)
                        : "", // moment(this.date.startEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                end_event_date:
                    this.dateSelected === "event"
                        ? this.formatDate(this.date.endEventDate)
                        : "", //moment(this.date.endEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                start_hospital_date:
                    this.dateSelected === "hospital"
                        ? this.formatDate(this.date.startHospitalDate)
                        : "", //moment(this.date.startHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                end_hospital_date:
                    this.dateSelected === "hospital"
                        ? this.formatDate(this.date.endHospitalDate)
                        : "", //moment(this.date.endHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            }
            this.$store.dispatch('dashboard/get_summary_recoder', data).then(res => {
                let list = [];
                let sum = [];
                Object.keys(res.data.data).forEach((val1) => {
                    Object.keys(res.data.data[val1]).forEach((val2) => {
                        Object.keys(res.data.data[val1][val2]).forEach((val3) => {
                            Object.keys(res.data.data[val1][val2][val3]).forEach((val4) => {
                                Object.values(res.data.data[val1][val2][val3]).forEach((val5) => {
                                    let obj = {
                                        '1': val1,
                                        '2': val2,
                                        '3': val3,
                                        '4': val4,
                                        '5': val5[0],
                                        '6': val5[1],
                                        '7': val5[2],
                                    }
                                    list.push(obj)
                                    if (sum[val3]) {
                                        sum[val3] = [sum[val3][0] += val5[0], sum[val3][1] += val5[1], sum[val3][2] += val5[2]];
                                    } else {
                                        sum[val3] = [val5[0], val5[1], val5[2]]
                                    }
                                })
                            })
                        })
                    })
                })
                this.list = list
                this.sum = sum
            })
        },
        setData() {
            this.$store.dispatch('MasterData/setProvince').then(res => {
                this.optionsprovince = res.data;
                this.optionsprovince = [
                    {'name': 'ทุกจังหวัด', 'code': ''},
                    ...this.optionsprovince
                ]
                let data = {
                    code: '',
                    type: '',
                    province: this.selected_province ? this.selected_province : '',
                }
                this.$store.dispatch('MasterData/setHospCode', data).then(res => {
                    this.optionshospital = res.data;
                    this.optionshospital = [
                        {'full_name': 'ทุกโรงพยาบาล', 'hospcode': ''},
                        ...this.optionshospital
                    ]
                    this.optionsdistrict = [
                        {'name': 'กรุณาเลือกอำเภอ', 'district_id': ''},
                    ]
                    this.hideLoading();
                })
            })
        },
        changeArea() {
            this.showLoading();
            this.$store.dispatch('MasterData/setProvince').then(res => {
                this.optionsprovince = res.data;
                this.optionsprovince = [
                    {'name': 'ทุกจังหวัด', 'code': ''},
                    ...this.optionsprovince.filter(data => this.selected_area == data.area_code)
                ]
                this.selected_province = '';
                this.selected_hospital = '';
                this.selected_district = '';
                this.hospital_type = '';
                this.optionsdistrict = [
                    {'name': 'กรุณาเลือกอำเภอ', 'district_id': ''},
                ]
                // let data = {
                //     code: this.selected_area,
                //     type: '',
                //     province: '',
                // }
                // this.$store.dispatch('MasterData/setHospCode', data).then(res => {
                //     this.optionshospital = res.data;
                //     this.optionshospital = [
                //         {'full_name': 'ทุกโรงพยาบาล', 'hospcode': ''},
                //         ...this.optionshospital
                //     ]
                this.hideLoading();
                // })
            })
        },
        changeProvince() {
            this.showLoading();
            if (this.selected_province) {
                this.$store.dispatch('MasterData/setDistrict', this.selected_province).then(res => {
                    this.optionsdistrict = res.data;
                    this.optionsdistrict = [
                        {'name': 'ทุกอำเภอ', 'district_id': ''},
                        ...this.optionsdistrict
                    ]
                    let data = {
                        code: '',
                        type: '',
                        province: this.selected_province ? this.selected_province : '',
                    }
                    this.$store.dispatch('MasterData/setHospCode', data).then(res => {
                        this.optionshospital = res.data;
                        this.optionshospital = [
                            {'full_name': 'ทุกโรงพยาบาล', 'hospcode': ''},
                            ...this.optionshospital
                        ]
                        this.selected_hospital = '';
                        this.selected_district = '';
                        this.hideLoading();
                    })
                })
            } else {
                this.selected_hospital = '';
                this.selected_district = '';
                this.optionsdistrict = [
                    {'name': 'กรุณาเลือกจังหวัด', 'district_id': ''},
                ]
                let data = {
                    code: this.selected_area,
                    type: '',
                    province: '',
                }
                this.$store.dispatch('MasterData/setHospCode', data).then(res => {
                    this.optionshospital = res.data;
                    this.optionshospital = [
                        {'full_name': 'ทุกโรงพยาบาล', 'hospcode': ''},
                        ...this.optionshospital
                    ]
                    this.hideLoading();
                })
            }
            this.setHospital();
        },
        changeDistrict() {
            this.showLoading();
            let data = {
                code: '',
                type: '',
                province: this.selected_province ? this.selected_province : '',
                district: this.selected_district ? this.selected_district : '',
            }
            this.$store.dispatch('MasterData/setHospCode', data).then(res => {
                this.optionshospital = res.data;
                this.optionshospital = [
                    {'full_name': 'ทุกโรงพยาบาล', 'hospcode': ''},
                    ...this.optionshospital
                ]
                this.selected_hospital = '';
                this.hideLoading();
            })
        },
        excel() {
            this.showLoading();
            let data = {
                area: this.selected_area,
                province: this.selected_province,
                district: this.selected_district,
                hospital: this.selected_hospital,
                start_event_date:
                    this.dateSelected === "event"
                        ? this.formatDate(this.date.startEventDate)
                        : "", // moment(this.date.startEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                end_event_date:
                    this.dateSelected === "event"
                        ? this.formatDate(this.date.endEventDate)
                        : "", //moment(this.date.endEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                start_hospital_date:
                    this.dateSelected === "hospital"
                        ? this.formatDate(this.date.startHospitalDate)
                        : "", //moment(this.date.startHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                end_hospital_date:
                    this.dateSelected === "hospital"
                        ? this.formatDate(this.date.endHospitalDate)
                        : "", //moment(this.date.endHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            }
            this.$store.dispatch('dashboard/getExcel', data).then(res => {
                this.hideLoading();
                window.open(res);
            })
        },
        showLoading() {
            this.$loading.show({
                background:
                    this.$swal.fire({
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            this.$swal.showLoading()
                        }
                    })
                , animation: true
            });
        },
        hideLoading() {
            this.$loading.hide();
            this.$swal.hideLoading()
        },
    }
}
</script>

<style scoped>
.main-form-oscc {
    background: #FFFFFF;
    border: 0.5px solid #A8A8A8;
    box-sizing: border-box;
    box-shadow: 1px 1px 9px 1px rgba(174, 194, 217, 0.3);
    border-radius: 5px;
}

thead.header-table-oscc tr th {
    border: 1px solid white;
    background-color: rgba(25, 46, 88, 1);
    color: white;
}

tbody tr td.body-table-oscc {
    border-right: 1px solid white;
    border-top: 1px solid rgba(25, 46, 88, 1);
    background-color: rgba(25, 46, 88, 1);
    color: white;
}

table th:first-child {
    border-radius: 20px 0 0 0;
}

table th:last-child {
    border-radius: 0 20px 0 0;
}

tbody:last-child tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}

tbody:last-child tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}

table {
    border-collapse: collapse;
}
</style>
